import { createAction, handleActions } from "redux-actions";
import { getFirebase } from "react-redux-firebase";
import moment from "moment";
import { createAsyncAction } from "redux-promise-middleware-actions";

export const setBetslipOpen = createAction("SET_BETSLIP_OPEN", (isOpen) => isOpen);
export const clickGame = createAction("CLICK_GAME");
export const setActiveSport = createAction("SET_ACTIVE_SPORT");
export const submitBet = createAsyncAction(
  "SUBMIT_BET",
  (data) => {
    console.log("SUBMIT_BET:", data);
    const placeWager = getFirebase().functions().httpsCallable("user-placeWager");
    getFirebase().functions().httpsCallable("user-placeWager");
    return placeWager({ isCoinflip: false, ...data });
  },
  (data) => {
    return { inflightBetID: data.unsubmittedToRemove, parlayInflight: true };
  }
);
export const submitBet2 = createAsyncAction(
  "SUBMIT_BET2",
  (data) => {
    console.log("SUBMIT_BET2:", data);
    const placeWager = getFirebase().functions().httpsCallable("user-placeWager2");
    getFirebase().functions().httpsCallable("user-placeWager2");
    return placeWager({ isCoinflip: false, ...data });
  },
  (data) => {
    return { inflightBetID: data.unsubmittedToRemove, parlayInflight: true };
  }
);
export const gradeWager = createAction("GRADE_WAGER");
export const gradeWager2 = createAction("GRADE_WAGER_2");
export const markParlayLegAsPush = createAction("MARK_PARLAY_LEG_AS_PUSH");
export const clearPlaceBetError = createAction("CLEAR_PLACE_BET_ERROR");
export const updateLines = createAction("UPDATE_LINES");
export const sportsbookShowSignup = createAction("SHOW_SIGNUP_ON_SPORTSBOOK", (show) => show);

export const getTimestamp = (timestamp) => {
  return moment.unix(timestamp / 1000).format("MMM DD h:mm a");
};

const convertAmericanToDecimal = (americanOdds) => {
  let decimalOdds = 1;
  if (americanOdds == 100 || americanOdds == -100) decimalOdds += 1;
  else if (americanOdds > 100) decimalOdds += Number((0.01 * americanOdds).toFixed(2));
  else if (americanOdds < 0) decimalOdds += Number((-100 / americanOdds).toFixed(2));
  return decimalOdds;
};

export const changeJuice = (line, juice) => {
  let impliedLine = 0;

  if (Math.abs(line) === 100) {
    impliedLine = 0.5;
  } else if (line < 0) {
    impliedLine = Math.abs(line) / (100 - line);
  } else if (line > 100) {
    impliedLine = 100 / (line + 100);
  }

  impliedLine *= 1.0 + juice * 0.01;
  if (impliedLine >= 0.99) {
    impliedLine = 0.99;
  }

  let returnPrice;

  if (impliedLine >= 0.5) {
    returnPrice = Math.floor((impliedLine / (1 - impliedLine)) * -100);
  } else {
    returnPrice = Math.ceil(((1 - impliedLine) / impliedLine) * 100);
  }
  return returnPrice;
};

const addPendingBet2 = (bet) => {
  console.log("Add pending bet", bet);
  // // Turn off for now
  // return;
  // // noinspection UnreachableCodeJS
  // /* eslint-disable no-unreachable */
  getFirebase()
    .firestore()
    .collection("users")
    .doc(bet.uid)
    .collection("pendingBets2")
    .add({
      added_datetime: new Date(),
      ...bet,
    })
    .then((docRef) => console.log("addPendingBet2", docRef.id))
    .catch((error) => console.log("addPendingBet2 Error:", error));
};

const addPendingBetToDB = (bet) => {
  console.log("Add bet pending", bet);
  getFirebase()
    .firestore()
    .collection("users")
    .doc(bet.uid)
    .collection("pendingBets")
    .where("lineId", "==", bet.lineId)
    .where("bet", "==", bet.bet)
    .get()
    .then((docRef) => {
      // console.log("!!! EPH checking bets", docRef.size);
      if (docRef.size > 0) {
        docRef.forEach((doc) => {
          // console.log("!!! Eph remove bet", doc.id);
          getFirebase().firestore().collection("users").doc(bet.uid).collection("pendingBets").doc(doc.id).delete();
        });
      } else {
        getFirebase()
          .firestore()
          .collection("users")
          .doc(bet.uid)
          .collection("pendingBets")
          .add(bet)
          .then((docRef) => console.log("Click Game Added Game", docRef))
          .catch((error) => console.log("Click Game Error", error));

        // getFirebase().firestore().collection("users").doc(bet.uid).update({ betSlipOpen: true });
      }
    });
};

async function EditParlay(payload) {
  console.log("editing parlay: ", payload);

  const db = await getFirebase().firestore();

  //update in global bets
  db.collection("bets")
    .where("betID", "==", payload.betID)
    .limit(1)
    .get()
    .then((res) => {
      let betDoc = res.docs[0];
      let betDocData = betDoc.data();
      let newOddsArray = [];
      let pushedBetIndex;

      betDocData.parlayCard.forEach((i) => {
        if (i.game.LineID !== payload.LineID) {
          newOddsArray.push(i.juice);
        } else if (i.game.LineID === payload.LineID) {
          pushedBetIndex = i;
        }
      });
      betDocData.parlayCard.splice(pushedBetIndex, 1);
      let newParlayOdds = calcParlayOdds(newOddsArray);

      betDocData.win = betDocData.risk * newParlayOdds - betDocData.risk;
      betDoc.ref.update(betDocData);
    });

  //update in user bets
  db.collection("users")
    .doc(payload.uid)
    .collection("bets")
    .where("betID", "==", payload.betID)
    .limit(1)
    .get()
    .then((res) => {
      let betDoc = res.docs[0];
      let betDocData = betDoc.data();
      let newOddsArray = [];
      let pushedBetIndex;

      betDocData.parlayCard.forEach((i) => {
        if (i.game.LineID !== payload.LineID) {
          newOddsArray.push(i.juice);
        } else if (i.game.LineID == payload.LineID) {
          pushedBetIndex = i;
        }
      });
      betDocData.parlayCard.splice(pushedBetIndex, 1);
      let newParlayOdds = calcParlayOdds(newOddsArray);

      betDocData.win = betDocData.risk * newParlayOdds - betDocData.risk;
      betDoc.ref.update(betDocData);
    });

  //update in group collection
  db.collection("groups")
    .doc(payload.gid)
    .collection("bets")
    .where("betID", "==", payload.betID)
    .limit(1)
    .get()
    .then((res) => {
      let betDoc = res.docs[0];
      let betDocData = betDoc.data();
      let newOddsArray = [];
      let pushedBetIndex;

      betDocData.parlayCard.forEach((i) => {
        if (i.game.LineID !== payload.LineID) {
          newOddsArray.push(i.juice);
        } else if (i.game.LineID == payload.LineID) {
          pushedBetIndex = i;
        }
      });
      betDocData.parlayCard.splice(pushedBetIndex, 1);
      let newParlayOdds = calcParlayOdds(newOddsArray);

      betDocData.win = betDocData.risk * newParlayOdds - betDocData.risk;
      betDoc.ref.update(betDocData);
    });
}

const calcParlayOdds = (parlayOddsArray) => {
  return parlayOddsArray.map((odd) => convertAmericanToDecimal(odd)).reduce((accum, current) => accum * current);
};

async function finishGradeWager(db, betDocData, result) {
  console.log("Grade", betDocData.uid, betDocData.user_bet_id);
  db.collection("users").doc(betDocData.uid).collection("bets").doc(betDocData.user_bet_id).update({ result: result });
  if (betDocData.gid) {
    db.collection("groups")
      .doc(betDocData.gid)
      .collection("bets")
      .doc(betDocData.user_bet_id)
      .update({ result: result })
      .catch((err) => console.log("ERROR: Could not update group bet item", err));
  }
}

async function finishGradeWager2(db, betDocData, result) {
  console.log("finish grade wager2", betDocData.uid, betDocData.user_bet_id);
  const res1 = await db
    .collection("users")
    .doc(betDocData.uid)
    .collection("bets2")
    .doc(betDocData.user_bet_id)
    .update({ result: result })
    .catch((err) => console.log("ERROR: Could not update group bet item", err));

  console.log("res1: ", res1);
  const res2 = await db
    .collection("groups")
    .doc(betDocData.gid)
    .collection("bets2")
    .doc(betDocData.user_bet_id)
    .update({ result: result })
    .catch((err) => console.log("ERROR: Could not update group bet item", err));
  console.log("res2: ", res2);
}

async function GradeWager(uid, betID, result) {
  console.log("trying to grade wager uid: ", uid, " and bet id: ", betID);
  const db = await getFirebase().firestore();
  db.collection("bets")
    .where("betID", "==", betID)
    .limit(1)
    .get()
    .then((res) => {
      let betDoc = res.docs[0];
      let betDocData = betDoc.data();
      console.log("betDoc: ", betDocData);
      // console.log("userBetDoc: ", userBetDoc);
      betDocData.result = result;
      betDoc.ref.update(betDocData);
      if (result === "win") {
        const adjustBalance = getFirebase().functions().httpsCallable("user-adjustBalanceGetRidOfMeLater");
        adjustBalance({
          uid: betDocData.uid,
          gid: betDocData.gid,
          adjust: betDocData.risk + betDocData.win,
        }).then(async () => {
          await finishGradeWager(db, betDocData, result);
        });
      } else if (result === "push") {
        const adjustBalance = getFirebase().functions().httpsCallable("user-adjustBalanceGetRidOfMeLater");
        adjustBalance({ uid: betDocData.uid, gid: betDocData.gid, adjust: betDocData.risk }).then(async () => {
          await finishGradeWager(db, betDocData, result);
        });
      } else {
        finishGradeWager(db, betDocData, result);
      }
    });
}
async function GradeWager2(uid, bet_id, result) {
  console.log("trying to grade wager 2 uid: ", uid, " and bet id: ", bet_id);
  const db = getFirebase().firestore();
  db.collection("bets2")
    .where("bet_id", "==", bet_id)
    .limit(1)
    .get()
    .then((res) => {
      let betDoc = res.docs[0];
      let betDocData = betDoc.data();
      console.log("betDoc: ", betDocData);
      // console.log("userBetDoc: ", userBetDoc);
      betDocData.result = result;
      betDoc.ref.update(betDocData);
      if (result === "win") {
        const adjustBalance = getFirebase().functions().httpsCallable("user-adjustBalanceGetRidOfMeLater");
        adjustBalance({
          uid: betDocData.uid,
          gid: betDocData.gid,
          adjust: betDocData.risk + betDocData.win,
        }).then(async () => {
          await finishGradeWager2(db, betDocData, result);
        });
      } else if (result === "push") {
        const adjustBalance = getFirebase().functions().httpsCallable("user-adjustBalanceGetRidOfMeLater");
        adjustBalance({ uid: betDocData.uid, gid: betDocData.gid, adjust: betDocData.risk }).then(async () => {
          await finishGradeWager2(db, betDocData, result);
        });
      } else {
        finishGradeWager2(db, betDocData, result);
      }
    });
}

const initialState = {
  lines: [],
  sportsbookState: [],
  parlay: [],
  showBetslip: false,
  unconfirmedBet: "",
  inflightBets: [],
  parlayInflight: false,
  showSignup: false,
  placeBetError: false,
  activeSport: "NFL",
  betslipOpen: false,
};

export default handleActions(
  {
    GRADE_WAGER: (state, { payload }) => {
      GradeWager(payload.uid, payload.betID, payload.result, payload.risk, payload.win);
      return {
        ...state,
      };
    },
    SET_BETSLIP_OPEN: (state, { payload }) => {
      return {
        ...state,
        betslipOpen: payload,
      };
    },
    GRADE_WAGER_2: (state, { payload }) => {
      GradeWager2(payload.uid, payload.betID, payload.result, payload.risk, payload.win);
      return {
        ...state,
      };
    },
    MARK_PARLAY_LEG_AS_PUSH: (state, { payload }) => {
      EditParlay(payload);
      return {
        ...state,
      };
    },
    SUBMIT_BET_LOADING: (state, { meta }) => {
      // getFirebase().analytics().logEvent("SUBMIT_BET_START");
      return {
        ...state,
        inflightBets: [...state.inflightBets, meta.inflightBetID],
        parlayInflight: meta.parlayInflight,
      };
    },
    SUBMIT_BET_SUCCESS: (state, { payload, meta }) => {
      console.log("SUBMIT BET PAYLOAD: ", payload);

      // const sftTrack = getFirebase().functions().httpsCallable("sftTrack");
      // sftTrack({ bucket: "SUBMIT_BET", data: payload })
      //   .then((result) => console.log("Cloud function return", result))
      //   .catch((error) => console.log("Cloud function error", error));

      if (payload.data.result !== "error") {
        getFirebase().analytics().logEvent("bet_placed", {
          success: payload.data.result,
          riskAmt: payload.data.bet.risk,
          gid: payload.data.bet.gid,
        });
      } else {
        getFirebase().analytics().logEvent("bet_placed", {
          success: payload.data.result,
        });
      }

      if (payload.data.result === "error") {
        // getFirebase().analytics().logEvent("SUBMIT_BET_FAILURE");
      } else {
        // getFirebase().analytics().logEvent("SUBMIT_BET_SUCCESS");
      }
      return {
        ...state,
        inflightBets: state.inflightBets.filter((item) => item !== meta.inflightBetID),
        parlayInflight: false,
        // This will customize later
        placeBetError: payload.data.result === "error" ? "Your Bet is Larger than your Balance" : false,
      };
    },
    SUBMIT_BET_ERROR: (state, { meta }) => {
      return {
        ...state,
        inflightBets: state.inflightBets.filter((item) => item !== meta.inflightBetID),
        parlayInflight: false,
      };
    },
    SUBMIT_BET2_LOADING: (state, { meta }) => {
      // getFirebase().analytics().logEvent("SUBMIT_BET_START");
      return {
        ...state,
        inflightBets: [...state.inflightBets, meta.inflightBetID],
        parlayInflight: meta.parlayInflight,
      };
    },
    SUBMIT_BET2_SUCCESS: (state, { payload, meta }) => {
      console.log("SUBMIT BET PAYLOAD: ", payload);

      // const sftTrack = getFirebase().functions().httpsCallable("sftTrack");
      // sftTrack({ bucket: "SUBMIT_BET", data: payload })
      //   .then((result) => console.log("Cloud function return", result))
      //   .catch((error) => console.log("Cloud function error", error));

      if (payload.data.result !== "error") {
        getFirebase().analytics().logEvent("bet_placed", {
          success: payload.data.result,
          riskAmt: payload.data.bet.risk,
          gid: payload.data.bet.gid,
        });
      } else {
        getFirebase().analytics().logEvent("bet_placed", {
          success: payload.data.result,
        });
      }

      if (payload.data.result === "error") {
        // getFirebase().analytics().logEvent("SUBMIT_BET_FAILURE");
      } else {
        // getFirebase().analytics().logEvent("SUBMIT_BET_SUCCESS");
      }
      return {
        ...state,
        inflightBets: state.inflightBets.filter((item) => item !== meta.inflightBetID),
        parlayInflight: false,
        // This will customize later
        placeBetError: payload.data.result === "error" ? "Your Bet is Larger than your Balance" : false,
      };
    },
    SUBMIT_BET2_ERROR: (state, { meta }) => {
      return {
        ...state,
        inflightBets: state.inflightBets.filter((item) => item !== meta.inflightBetID),
        parlayInflight: false,
      };
    },

    CLEAR_PLACE_BET_ERROR: (state) => {
      return {
        ...state,
        placeBetError: false,
      };
    },
    SHOW_SIGNUP_ON_SPORTSBOOK: (state, { payload }) => {
      return {
        ...state,
        showSignup: payload,
      };
    },
    SET_ACTIVE_SPORT: (state, { payload }) => {
      return {
        ...state,
        activeSport: payload.activeSport,
      };
    },
    CLICK_GAME: (state, { payload }) => {
      // const sftTrack = getFirebase().functions().httpsCallable("sftTrack");
      // sftTrack({ bucket: "CLICK_GAME", data: payload });
      // .then((result) => console.log("Cloud function return", result))
      // .catch((error) => console.log("Cloud function error", error));

      // getFirebase().analytics().logEvent("CLICK_GAME");
      if (payload.betOrigin === "standard") {
        addPendingBet2(payload);
      } else {
        addPendingBetToDB(payload);
      }
      return state;
    },
  },
  initialState
);
